module.exports = {
  /** Site MetaData (Required all)*/
  title: `Skywalker512`,                           // (* Required)
  description: `我是 Skywalker512 正在学习前端`, // (* Required)
  author: `JSkywalker512`,                         // (* Required)
  siteUrl: 'https://512.pub',       // (* Required) 

  /** Header */
  profileImageFileName: 'profile.png', // include filename extension ex.'profile.jpg'
    // The Profile image file is located at path "./images/"
    // If the file does not exist, it is replaced by a random image.

  /** Home > Bio information*/
  comment: '前端初学者 / javascript, react ...',
  name: 'Skywalker512',
  company: 'Redrock',
  location: 'Chongqing',
  email: 'houzhenhong@outlook.com',
  website: 'https://512.pub',           // ex.'https://junhobaik.github.io'
  linkedin: '',                                     // ex.'https://www.linkedin.com/in/junho-baik-16073a19ab'
  facebook: '',                                     // ex.'https://www.facebook.com/zuck' or 'https://www.facebook.com/profile.php?id=000000000000000'
  instagram: '', // ex.'https://www.instagram.com/junhobaik'
  github: 'https://github.com/skywalker512',           // ex.'https://github.com/junhobaik'

  /** Post */
  enablePostOfContents: true,     // TableOfContents activation (Type of Value: Boolean. Not String)
  disqusShortname: 'skyblog-2',   // comments (Disqus sort-name)
  enableSocialShare: false,        // Social share icon activation (Type of Value: Boolean. Not String)

  /** Optional */
  googleAnalytics: 'UA-145706329-5',                                  // Google Analytics TrackingID. ex.'UA-123456789-0'
  googleSearchConsole: '', // content value in HTML tag of google search console ownership verification 
    // Please correct the adsense client number(ex.5001380215831339) in the './static/ads.txt' file.
};
